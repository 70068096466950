// Typeramp designed to work with base 16px
// If you adjust the baseSize then adjust the typeramp manually
export default [
  '12px', // fontSize[0]
  '14px', // fontSize[1]
  '16px', // fontSize[2]
  '18px', // fontSize[3]
  '20px', // fontSize[4]
  '24px', // fontSize[5]
  '30px', // fontSize[6]
  '36px', // fontSize[7]
  '48px', // fontSize[8]
  '60px', // fontSize[9]
  '72px', // fontSize[10]
];
