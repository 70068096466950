import styled from "styled-components";

const SiteHeader = styled.header`
  padding: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme, context }) =>
    context === "epk" ? theme.space[5] : 0};

  h1 {
    margin: 0;
    padding: 0;
    font-size: ${({ theme }) => theme.fontSize[3]};
  }

  a,
  a:hover {
    text-decoration: none;
  }
`;

const EPKHeader = styled.div`
  background-color: ${({ theme }) => theme.color.brick};
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize[1]};
  width: 100vw;
  height: ${({ theme }) => theme.space[5]};
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0.9;
`;

export default {
  SiteHeader,
  EPKHeader,
};
