import styled from "styled-components";

const Section = styled.div`
  max-width: ${({ theme }) => theme.space[18]};
  width: 100%;
  margin: ${({ theme }) => `${theme.space[3]} auto ${theme.space[7]}`};

  iframe {
    max-width: 100%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ theme }) => `${theme.space[3]} auto ${theme.space[8]}`};
  }
`;

const SectionHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space[4]};

  h4 {
    font-family: "ApfelGrotezk-Regular", "Helvetica", Arial, sans-serif;
    font-size: ${({ theme }) => theme.fontSize[0]};
    color: ${({ theme }) => theme.color.brick};
    font-weight: normal;
    padding: 0;
    margin: 0;
    padding-right: ${({ theme }) => theme.space[2]};
    letter-spacing: 0.25px;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[1]};
      letter-spacing: 0.5px;
    }
  }

  hr {
    border: 0;
    border-top: ${({ theme }) => `1px solid ${theme.color.brick}`};
    width: 100%;
    flex: 1;
  }
`;

const SectionColumn = styled.div`
  padding: ${({ theme }) => `0 ${theme.space[4]}`};
`;

export default {
  Section,
  SectionHeading,
  SectionColumn,
};
