import React from "react";
import Link from "../../common/Link";
import Styles from "./Styles";

const SiteHeader = ({ context }) => {
  return (
    <>
      {context === "epk" && (
        <Styles.EPKHeader>
          <span>
            The Goudies Press Kit - <Link to="/">View Main site</Link>
          </span>
        </Styles.EPKHeader>
      )}
      <Styles.SiteHeader context={context}>
        <Link to="/">
          <h1>{context === "epk" ? "The Goudies // EPK" : "The Goudies"}</h1>
        </Link>
      </Styles.SiteHeader>
    </>
  );
};

export default SiteHeader;
