import React from "react"
import PropTypes from "prop-types"
import Styles from "./Styles"
import Link from "../../common/Link"
import Section from "../../common/Section"

const Footer = ({ children, ...props }) => {
  return (
    <Styles.SiteFooter {...props}>
      <Styles.SiteFooterInner>
        <Styles.SiteFooterColumns>
          <Styles.SiteFooterColumn>
            <Section heading="Listen some more">
              <iframe
                src="https://open.spotify.com/embed/playlist/6kfL5kGuMDqaY5zF6kpnwJ"
                width="384"
                height="384"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </Section>
          </Styles.SiteFooterColumn>

          <Styles.SiteFooterColumn>
            <Section heading="Contact">
              <p>
                For all booking, press, and other enquiries please email{" "}
                <a href="mailto:hello@thegoudies.com">hello@thegoudies.com</a>
              </p>
            </Section>

            <Section heading="Send us a tip!">
              <p>
                If you’d like to help fund our next release you can{" "}
                <a href="https://thegoudies.ck.page/products/tip-jar">
                  send us a tip here
                </a>{" "}
                or email{" "}
                <a href="mailto:hello@thegoudies.com">hello@thegoudies.com</a>{" "}
                and we’ll send you details.
              </p>
            </Section>

            <Section heading="Colophon">
              <p>
                Typeset in Young Serif by{" "}
                <a href="https://noirblancrouge.com/fonts/young-serif/">
                  Bastien Sozeau
                </a>{" "}
                and Apfel Grotesk by{" "}
                <a href="http://collletttivo.it">Collletttivo</a>.
              </p>
            </Section>
          </Styles.SiteFooterColumn>
        </Styles.SiteFooterColumns>

        <Styles.SiteFooterLegal>
          <p>
            This site uses{" "}
            <a href="https://usefathom.com/ref/38H5YM">
              privacy &amp; people friendly analytics.
            </a>
          </p>
          <p>
            &copy; Copyright The Goudies -{" "}
            <a href="https://goudieworks.co">Goudie Works Ltd</a>{" "}
            {new Date().getFullYear()}. Company number 11656551. Registered in
            England and Wales. All rights reserved.
          </p>
        </Styles.SiteFooterLegal>
      </Styles.SiteFooterInner>
    </Styles.SiteFooter>
  )
}

Footer.propTypes = {}

export default Footer
