import { createGlobalStyle } from "styled-components"

import youngSerifWOFF from "./fonts/YoungSerif-Regular.woff"
import youngSerifWOFF2 from "./fonts/YoungSerif-Regular.woff2"

import apfelGrotezkRegularWOFF from "./fonts/ApfelGrotezk-Regular.woff"
import apfelGrotezkRegularWOFF2 from "./fonts/ApfelGrotezk-Regular.woff2"

import apfelGrotezkFettWOFF from "./fonts/ApfelGrotezk-Fett.woff"
import apfelGrotezkFettWOFF2 from "./fonts/ApfelGrotezk-Fett.woff2"

const GlobalStyle = createGlobalStyle`
 @font-face {
    font-family: 'YoungSerif-Regular';
    src: url(${youngSerifWOFF}) format('woff2'), url(${youngSerifWOFF2}) format('woff2');
  }
  
  @font-face {
    font-family: 'ApfelGrotezk-Fett';
    src: url(${apfelGrotezkFettWOFF}) format('woff2'), url(${apfelGrotezkFettWOFF2}) format('woff2');
  }

  @font-face {
    font-family: 'ApfelGrotezk-Regular';
    src: url(${apfelGrotezkRegularWOFF}) format('woff2'), url(${apfelGrotezkRegularWOFF2}) format('woff2');
  }

  * {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    background: ${({ theme }) => theme.color.lightParchment};
    color: ${({ theme }) => theme.color.darkBrown};
    font-family: 'ApfelGrotezk-Regular', 'Helvetica', Arial, sans-serif;
    font-size: ${({ theme }) => theme.fontSize[2]};

    /* @media ${({ theme }) => theme.device.mobileL} {
      padding: ${({ theme }) => theme.space[3]};
    } */
  }

  // TYPOGRAPHY
  h1, h2, h3, h4 {
    font-family: 'YoungSerif-Regular', Georgia, 'Times New Roman', Times, serif;
    text-transform: uppercase;
    font-weight: normal;
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSize[8]};

    /* @media ${({ theme }) => theme.device.mobileL} {
      font-size: ${({ theme }) => theme.fontSize[9]};
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[10]};
    } */
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize[6]};
    line-height: ${({ theme }) => theme.space[5]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[8]};
      line-height: ${({ theme }) => theme.space[6]};
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: ${({ theme }) => theme.fontSize[9]};
      line-height: ${({ theme }) => theme.space[7]};
    }
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSize[4]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[6]};
    }
  }

  h4 {
    font-size: ${({ theme }) => theme.fontSize[2]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[3]};
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: ${({ theme }) => theme.fontSize[4]};
    }

    /* @media ${({ theme }) => theme.device.mobileL} {
      font-size: ${({ theme }) => theme.fontSize[3]};
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[4]};
    } */
  }

  p {
    font-family: 'ApfelGrotezk-Regular', 'Helvetica', Arial, sans-serif;
    font-size: ${({ theme }) => theme.fontSize[2]};
    line-height: 1.7;

    strong {
      font-family: 'ApfelGrotezk-Fett', 'Helvetica', Arial, sans-serif;
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[3]};
      line-height: 1.6;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: ${({ theme }) => theme.fontSize[4]};
    }

    span {
      font-family: 'YoungSerif-Regular', Georgia, 'Times New Roman', Times, serif;
      text-transform: uppercase;
    }
  }

  a {
    color: ${({ theme }) => theme.color.darkBrown};
  }

  blockquote {
    font-style: italic;
    font-size: ${({ theme }) => theme.fontSize[5]};
    border-left: ${({ theme }) =>
      `${theme.borderWidth[4]} solid ${theme.color.parchment}`};
    margin: ${({ theme }) => `${theme.space[3]} 0`};
    padding-left: ${({ theme }) => theme.space[3]};
  }
`

export default GlobalStyle
