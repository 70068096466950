// Starting with base size to build an exponential scale
const baseSize = 16;

export default [
  `${baseSize * 0.25}px`, // 4px space[0]
  `${baseSize * 0.5}px`, // 8px space[1]
  `${baseSize * 0.75}px`, // 12px space[2]
  `${baseSize * 1}px`, // 16px space[3]
  `${baseSize * 1.5}px`, // 24px space[4]
  `${baseSize * 2}px`, // 32px space[5]
  `${baseSize * 3}px`, // 48px space[6]
  `${baseSize * 4}px`, // 64px space[7]
  `${baseSize * 6}px`, // 96px space[8]
  `${baseSize * 8}px`, // 128px space[9]
  `${baseSize * 12}px`, // 192px space[10]
  `${baseSize * 16}px`, // 256px space[11]
  `${baseSize * 24}px`, // 384px space[12]
  `${baseSize * 32}px`, // 512px space[13]
  `${baseSize * 40}px`, // 640px space[14]
  `${baseSize * 48}px`, // 768px space[15]
  `${baseSize * 56}px`, // 896px space[16]
  `${baseSize * 64}px`, // 1024px space[17]
  `${baseSize * 72}px`, // 1152px space[18]
  `${baseSize * 80}px`, // 1280px space[19]
];
