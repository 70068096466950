import borderRadius from "./borderRadius";
import borderWidth from "./borderWidth";
import boxShadow from "./boxShadow";
import color from "./color";
import device from "./device";
import fontSize from "./fontSize";
import gradient from "./gradient";
import space from "./space";

export default {
  borderRadius,
  borderWidth,
  boxShadow,
  color,
  device,
  fontSize,
  gradient,
  space,
};
