import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/Layout"
import PageContent from "../components/common/PageContent"
import YouTubeEmbed from "../components/common/YouTubeEmbed"
// markup
const NotFoundPage = ({ location }) => {
  console.log(location)
  const path = location.pathname ? location.pathname : ""

  return (
    <Layout>
      <title>Not Found</title>
      <PageContent>
        <h1>Page not found</h1>
        <p>
          <p>Sorry, there&apos;s nothing here{path ? ` at ${path}` : "..."}</p>
          <Link to="/">Head home &rarr;</Link>
        </p>
        <YouTubeEmbed
          youtubeId="W8kPUcrqN8c"
          title="The Goudies - Town Called Bel Air"
        />
      </PageContent>
    </Layout>
  )
}

export default NotFoundPage
