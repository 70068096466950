import React from "react";
import { ThemeProvider } from "styled-components";
import theme from "../../../styles/theme";
import GlobalStyle from "../../../styles/GlobalStyle";
import SiteHeader from "../SiteHeader";
import SiteFooter from "../SiteFooter";

export default function Layout({ children, context }) {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <SiteHeader context={context} />
        {children}
        <SiteFooter context={context} />
      </React.Fragment>
    </ThemeProvider>
  );
}
