import styled from "styled-components";
import { Styles as SectionStyles } from "../../common/Section";

const SiteFooter = styled.footer`
  margin-top: ${({ theme }) => theme.space[8]};
  background-color: ${({ theme }) => theme.color.darkBrown};
  color: ${({ theme }) => theme.color.parchment};
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    font-size: ${({ theme }) => theme.fontSize[1]};
  }

  a {
    color: ${({ theme }) => theme.color.parchment};
  }
`;

const SiteFooterInner = styled.div`
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[4]}`};
  width: 100%;
  max-width: ${({ theme }) => theme.space[18]};
`;

const SiteFooterColumn = styled.div`
  ${SectionStyles.Section} {
    margin-bottom: ${({ theme }) => theme.space[6]};
  }
`;

const SiteFooterColumns = styled.div`
  @media ${({ theme }) => theme.device.tablet} {
    display: grid;
    grid-gap: ${({ theme }) => theme.space[6]};
    grid-template-columns: ${({ theme }) => `${theme.space[11]} 1fr`};
  }

  @media ${({ theme }) => theme.device.laptop} {
    grid-template-columns: ${({ theme }) => `${theme.space[12]} 1fr`};
  }
`;

const SiteFooterLegal = styled.div`
  margin: ${({ theme }) => `${theme.space[8]} 0 0`};
`;

export default {
  SiteFooter,
  SiteFooterInner,
  SiteFooterColumn,
  SiteFooterColumns,
  SiteFooterLegal,
};
