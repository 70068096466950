import React from "react";
import Styles from "./Styles";

const Section = ({ id, number, heading, children }) => {
  return (
    <Styles.Section>
      <a id={id} />
      {heading && (
        <Styles.SectionHeading>
          <h4>{`${
            number ? `${("0" + number).slice(-2)}. ` : ""
          }${heading}`}</h4>
          <hr />
        </Styles.SectionHeading>
      )}
      {children}
    </Styles.Section>
  );
};

export default Section;
